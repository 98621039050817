/* eslint-disable prettier/prettier */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import parse from 'html-react-parser'
import cn from 'classnames';

import isEmpty from 'lodash/isEmpty';

import Trans from 'src/components/shared/Trans/Trans';

import Columns from 'src/components/shared/Columns/Columns';
import { declarationPropTypes } from 'src/prop_types/declaration_prop_types';
import i18next from 'src/utils/i18n';
// eslint-disable-next-line no-unused-vars
import parsedHtml from 'src/components/shared/ParseHtml.module.scss';
import CompanyCard from './CompanyCard/CompanyCard';
import ProcessorCard from './ProcessorCard/ProcessorCard';
import DeclarationEditableText from './DeclarationEditableText/DeclarationEditableText';
import DeclarationHeader from './DeclarationHeader/DeclarationHeader';

import styles from './ContractDeclarationDetails.module.scss';

const transformProcessor = (processor) => {
  if (!processor) return null;
  
  return {
    id: processor.id,
    name: processor.name || processor.attributes?.name,
    companyName: processor.companyName || processor.attributes?.company_name,
    contactName: processor.contactName || processor.attributes?.contact_name,
    contactEmail: processor.contactEmail || processor.attributes?.contact_email,
    contactPhone: processor.contactPhone || processor.attributes?.contact_phone
  };
};

const DeclarationDetailsView = ({ declaration, isEditable, t }) => {
  console.log('ContractDeclarationDetails - declaration:', declaration);
  console.log('ContractDeclarationDetails - processor:', declaration.processor);
  console.log('ContractDeclarationDetails - processorRecords:', declaration.processorRecords);

  return (
    <article className={styles.declarationDetailsView}>
      {declaration.id && <h2 className={styles.h2}>{declaration.title}</h2>}

      <section className={styles.purposeSection}>
        <DeclarationHeader
          isEditable={isEditable}
          name="purposeTitle"
          initialValue={ declaration.purposeTitle }
          placeholder={t('consent_declaration.purpose_title')}
        />

        <div
          className={parsedHtml.parsedHtml}>
          {parse(declaration.purpose)}
        </div>
        
        <DeclarationHeader
          isEditable={false}
          name="type"
          initialValue={ i18next.t('shared.sandbox_header') }
        />

        <div>
          { declaration.test ? 'Test' : 'Standard' }
        </div>
      </section>

      {declaration.processor && (
        <section className={styles.processorsSection}>
          <DeclarationHeader
            className={styles.heading}
            isEditable={isEditable}
            name="processorTitle"
            initialValue={i18next.t('contract_declaration.processor_title')}
            placeholder={t('contract_declaration.processor_title')}
          />

          <div className={styles.processorDescription}>
            {declaration.processorDescription}
          </div>

          <div className={styles.processors}>
            <ProcessorCard 
              number={1} 
              processor={transformProcessor(declaration.processor)} 
            />
          </div>
        </section>
      )}

      {declaration.processorRecords?.length > 0 && (
        <section className={styles.processorsSection}>
          <DeclarationHeader
            className={styles.heading}
            isEditable={false}
            name="processorRecordsTitle"
            initialValue={i18next.t('shared.processor_records')}
          />

          <div className={styles.processors}>
            {declaration.processorRecords.map((processor, index) => (
              <ProcessorCard 
                key={processor.id || index}
                processor={transformProcessor(processor)}
                number={index + 1}
              />
            ))}
          </div>
        </section>
      )}

      {declaration.id && (
        <div
          className={cn(styles.companySection, {
            [styles.noThirdParty]: isEmpty(declaration.thirdParties),
          })}
        >
          <div className={styles.detailsContainer}>
            <div className={styles.companyDetails}>
              <DeclarationHeader
                className={styles.heading}
                isEditable={isEditable}
                name="companyDetailsTitle"
                initialValue={ i18next.t('contract_declaration.company_details_title') }
                placeholder={t('consent_declaration.company_details_title')}
              />
              <CompanyCard
                company={{
                  ...declaration.organization,
                  contactEmail: declaration.organization.companyEmail,
                }}
              />
            </div>
          </div>
        </div>
      )}

      {declaration.id && (
        <section className={styles.withdrawalSection}>
          <DeclarationHeader
            isEditable={isEditable}
            name="withdrawalTitle"
            initialValue={ i18next.t('contract_declaration.withdrawal_title') }
            placeholder={t('consent_declaration.withdrawal_title')}
          />
        
          <div
            className={parsedHtml.parsedHtml}>
            {parse(declaration.withdrawDetails)}
          </div>
        </section>
      )}
    </article>
  );
};

DeclarationDetailsView.propTypes = {
  ...declarationPropTypes,
  isEditable: PropTypes.bool,
};

DeclarationDetailsView.defaultProps = {
  isEditable: false,
};

export default withNamespaces()(DeclarationDetailsView);

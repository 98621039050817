import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';

import { withNamespaces } from 'react-i18next';
import Trans from 'src/components/shared/Trans/Trans';

import { Link } from 'react-router-dom';
import {
  showDeclaration,
  revokeLinkedConsents,
  getConsentDeclarationConsentsCSV,
} from 'src/api/consent_declarations';
import { getConsentsFnForDeclaration } from 'src/api/consents';
import Button from 'src/components/shared/Button/Button';
import CaptionedInfo from 'src/components/shared/CaptionedInfo/CaptionedInfo';
import DeclarationDetailsView from 'src/components/shared/DeclarationDetailsView/DeclarationDetailsView';
import DeclarationFormModal from 'src/components/shared/DeclarationFormModal/DeclarationFormModal';
import BreadcrumbTitle from 'src/components/shared/BreadcrumbTitle/BreadcrumbTitle';
import Loading from 'src/components/shared/Loading/Loading';
import withToast from 'src/components/Toast/withToast/withToast';
import toastPropTypes from 'src/prop_types/toastPropTypes';
import DownloadButton from 'src/components/shared/DownloadButton/DownloadButton';

import VersionActionChoiceModal from './VersionActionChoiceModal/VersionActionChoiceModal';
import VersionsTable from './VersionsTable/VersionsTable';

import ConsentsPage from '../ConsentsPage/ConsentsPage';

import styles from './ConsentDeclarationDetailsPage.module.scss';
import WarningModal from '../shared/WarningModal/WarningModal';

class ConsentDeclarationDetailsPage extends Component {
  static propTypes = {
    match: ReactRouterPropTypes.match.isRequired,
    t: PropTypes.func.isRequired,
    openToast: toastPropTypes.openToast.isRequired,
  };

  state = {
    formModal: false,
    revokeModal: false,
    choiceModal: false,
    declaration: {},
    editedDeclaration: {},
    isLoading: true,
    events: [],
    getConsents: () => ({ consents: [], meta: { currentPage: 1, totalPages: 1, totalEntries: 0 } }),
  };

  componentDidMount = async () => {
    const {
      match: {
        params: { id },
      },
    } = this.props;

    const getConsents = await getConsentsFnForDeclaration(id);
    const { events, ...declaration } = await showDeclaration(id);

    this.setState({
      declaration,
      events,
      isLoading: false,
      getConsents,
    });
  };

  onSubmit = declaration => {
    this.setState({ formModal: false, choiceModal: true, editedDeclaration: declaration });
  };

  toggleFormModal = () => this.setState(prevState => ({ formModal: !prevState.formModal }));

  toggleChoiceModal = () => this.setState(prevState => ({ choiceModal: !prevState.choiceModal }));

  toggleRevokeModal = () => this.setState(prevState => ({ revokeModal: !prevState.revokeModal }));

  revokeLinkedConsentsWithDeclaration = () => {
    const {
      match: {
        params: { id },
      },
      openToast,
      t,
    } = this.props;
    const { toggleRevokeModal } = this;

    revokeLinkedConsents(id).then(({ meta }) => {
      toggleRevokeModal();
      openToast({
        message: t('consents_page.toasts.consents_revoked', { count: meta.revoked_count }),
        type: 'danger',
      });
    });
  };

  handleDownloadCsvClick = () => {
    const {
      match: {
        params: { id },
      },
    } = this.props;

    const {
      declaration: { title },
    } = this.state;

    getConsentDeclarationConsentsCSV({ id }).then(res => {
      const csvData = new Blob([res], { type: 'text/csv' });
      const csvUrl = URL.createObjectURL(csvData);
      const hiddenElement = document.createElement('a');
      hiddenElement.href = csvUrl;
      hiddenElement.target = '_blank';
      hiddenElement.download = `${title}.csv`;
      hiddenElement.click();
    });
  };

  render() {
    const {
      editedDeclaration,
      declaration,
      events,
      formModal,
      choiceModal,
      isLoading,
      revokeModal,
      getConsents,
    } = this.state;

    const {
      match: {
        params: { id },
      },
      t,
    } = this.props;

    const {
      toggleChoiceModal,
      toggleFormModal,
      onSubmit,
      toggleRevokeModal,
      revokeLinkedConsentsWithDeclaration,
      handleDownloadCsvClick,
    } = this;

    const { processor: { id: oldProcessorId } = {} } = declaration;
    const { processor: { id: newProcessorId } = {} } = editedDeclaration;

    const didProcessorChange = oldProcessorId !== newProcessorId;

    console.log('Declaration data:', declaration);

    return (
      <main className={styles.consentDeclarationDetailsPage}>
        {isLoading ? (
          <Loading data-testid="loading" />
        ) : (
          <Fragment>
            <BreadcrumbTitle
              path={[
                {
                  label: `${t('shared.consent_declarations')}`,
                  pathElement: '/consent_declarations',
                },
              ]}
              current={id}
            >
              <div>
                <Button
                  kind="outlineDanger"
                  className={styles.revokeButton}
                  onClick={toggleRevokeModal}
                  type="submit"
                >
                  <Trans noMd>consent_declaration_details_page.revoke_all_consents_button</Trans>
                </Button>
                <Button kind="outline" onClick={toggleFormModal} type="button">
                  <Trans noMd>consent_declaration_details_page.edit_declaration_button</Trans>
                </Button>
              </div>
            </BreadcrumbTitle>
            <section className={styles.content}>
              <div className={styles.creationInfo}>
                <CaptionedInfo className={styles.info} title={t('shared.created_by')} underlined>
                  {declaration.createdBy ? declaration.createdBy.fullName : 'API'}
                </CaptionedInfo>
                
                <CaptionedInfo className={styles.info} title={t('shared.created_at')} underlined>
                  <Trans date>{declaration.createdAt}</Trans>
                </CaptionedInfo>

                <CaptionedInfo className={styles.info} title={ t('shared.associated_consents') } underlined>
                  <p>{t('shared.valid_consents')}: {declaration.validConsents} (+{declaration.validConsentsToday})</p>
                  <p>{t('shared.withdrawn_contents')}: {declaration.withdrawConsents} (+{declaration.withdrawConsentsToday})</p>
                  <p>{t('shared.total_consents')}: {declaration.consentsCount}</p>
                </CaptionedInfo>

                <div className={styles.overlay}>
                  <Button kind="primary" component={Link} to={`/embed/${id}`} type="button">
                    {t('consent_declaration_details_page.embed_button')}
                  </Button>
                </div>
                <div className={styles.downloadWrapper}>
                  <DownloadButton
                    onClick={handleDownloadCsvClick}
                    description="Download full list of consents for this Consent Declaration:"
                  >
                    Download CSV
                  </DownloadButton>
                </div>
              </div>

              <DeclarationDetailsView {...{ declaration }} />
            </section>

            <div className={styles.consentsSection}>
              <ConsentsPage getConsents={getConsents} fromConsentDeclaration />
            </div>
            <CaptionedInfo
              title={t('consent_declaration_details_page.captioned_info.versions_history')}
            >
              <VersionsTable {...{ events }} />
            </CaptionedInfo>
            {choiceModal && (
              <VersionActionChoiceModal
                open={choiceModal}
                onClose={toggleChoiceModal}
                declaration={editedDeclaration}
                didProcessorChange={didProcessorChange}
              />
            )}
            <DeclarationFormModal
              open={formModal}
              onClose={toggleFormModal}
              onSubmit={onSubmit}
              declaration={declaration}
              isEdit
            />
            <WarningModal
              title={t('consent_declaration_details_page.revoke_all_consents_modal.title')}
              open={revokeModal}
              onClose={toggleRevokeModal}
              onAccept={revokeLinkedConsentsWithDeclaration}
              acceptLabel={t('shared.revoke')}
            >
              {t('shared.revoke_all_consents_modal.content')}
            </WarningModal>
          </Fragment>
        )}
      </main>
    );
  }
}

export default withNamespaces()(withToast(ConsentDeclarationDetailsPage));

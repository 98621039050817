/* eslint-disable prettier/prettier */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import cn from 'classnames';

import Trans from 'src/components/shared/Trans/Trans';
import { shouldDisplayError } from 'src/utils/shouldDisplayError';
import { shouldDisplayCheckmark } from 'src/utils/shouldDisplayCheckmark';
import ErrorMessage from 'src/components/shared/ErrorMessage/ErrorMessage';
import Label from 'src/components/shared/Label/Label';
import { isRequired } from 'src/utils/validators';
import Editor from '../Editor/Editor';

import styles from './TextEditorField.scss';

const TextEditorField = ({ label, name, className, required, ...props }) => {
  return (
    <div className={cn(className, styles.field)}>
      <Field {...{ name }} validate={required ? isRequired : null}>
        {({ input, meta: { valid, error, touched, dirty, submitError, dirtySinceLastSubmit } }) => (
          <Fragment>
            <Label
              htmlFor={name}
              required={required}
              shouldDisplayCheckmark={shouldDisplayCheckmark({
                required,
                valid,
                value: input.value,
                submitError,
                dirtySinceLastSubmit,
              })}
            >
              {label}
            </Label>

            <div 
              className={cn(styles.editorWrapper, {
                [styles.error]: shouldDisplayError({
                  valid,
                  touched,
                  dirty,
                  dirtySinceLastSubmit,
                  submitError,
                })
              })}
            >
              <Editor
                name={name}
                value={input.value}
                onChange={(content) => {
                  input.onChange(content);
                  props.setFormValue(name, content);
                }}
                className={cn({
                  error: shouldDisplayError({
                    valid,
                    touched,
                    dirty,
                    dirtySinceLastSubmit,
                    submitError,
                  })
                })}
              />
            </div>

            {shouldDisplayError({ valid, touched, dirty, dirtySinceLastSubmit, submitError }) && (
              <ErrorMessage>
                <Trans noMd>{error || submitError}</Trans>
              </ErrorMessage>
            )}
          </Fragment>
        )}
      </Field>
    </div>
  );
};

TextEditorField.defaultProps = {
  required: false,
  label: 'Label',
  className: '',
};

TextEditorField.propTypes = {
  required: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  setFormValue: PropTypes.func.isRequired,
};

export default TextEditorField;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Form from 'src/components/shared/OnBlurForm/OnBlurForm';
import { withNamespaces } from 'react-i18next';
import { FormSpy } from 'react-final-form';

import withToast from 'src/components/Toast/withToast/withToast';
import Trans from 'src/components/shared/Trans/Trans';
import { createProcessor, getAllProcessors } from 'src/api/processors';

import Button from 'src/components/shared/Button/Button';
import ProcessorFormFields from 'src/components/shared/ProcessorFormFields/ProcessorFormFields';
import ProcessorField from './ProcessorField/ProcessorField';
import Checkbox from 'src/components/shared/Checkbox/Checkbox';
import Label from 'src/components/shared/Label/Label';

import styles from './ProcessorStep.module.scss';

class ProcessorStep extends Component {
  static propTypes = {
    setProcessorField: PropTypes.func.isRequired,
    setProcessorRecords: PropTypes.func.isRequired,
    processorId: PropTypes.string,
    processorRecordIds: PropTypes.arrayOf(PropTypes.string),
    t: PropTypes.func.isRequired,
    openToast: PropTypes.func.isRequired,
  };

  static defaultProps = {
    processorId: null,
    processorRecordIds: [],
  };

  state = {
    processorRecords: [],
    processorOptions: [],
    processors: [],
  };

  componentDidMount() {
    this.fetchProcessorsOptions();
  }

  fetchProcessorsOptions = () =>
    getAllProcessors()
      .then(({ processors }) => {
        const { processorId } = this.props;
        const mappedProcessors = processors.map(({ name, id }) => ({ label: name, value: id }));
        console.log('Mapped processors:', mappedProcessors);
        
        const controller = processors.find(p => p.id === processorId);
        this.setState({ 
          processorOptions: mappedProcessors, 
          processors,
          processorRecords: controller ? controller.processorRecords : []
        });

        return mappedProcessors;
      })
      .catch(() => {
        const { t, openToast } = this.props;
        openToast({
          message: t('declaration_form.processor_step.errors.fetch_processor'),
          type: 'danger',
          inModal: true,
        });
      });

  createNewProcessor = processor =>
    new Promise(resolve => {
      const { setProcessorField, t, openToast } = this.props;
      const { findProcessorById } = this;

      createProcessor(processor)
        .then(this.fetchProcessorsOptions)
        .then(processors => processors.find(({ label }) => label === processor.name))
        .then(({ value: id }) => {
          setProcessorField(findProcessorById(id));
          resolve();
        })
        .catch(({ status }) => {
          if (status === 422) {
            return resolve({
              name: t('error_messages.processor_name_already_exists'),
            });
          }

          return openToast({
            message: t('declaration_form.processor_step.errors.create_processor'),
            type: 'danger',
            inModal: true,
          });
        });
    });

  findProcessorById = id => {
    const { processors } = this.state;
    const controller = processors.find(p => p.id === id);
    if (controller) {
      this.setState({ 
        processorRecords: controller.processorRecords || [],
        selectedProcessor: controller
      });
      
      this.props.setProcessorField(controller);
      this.props.setProcessorRecords([]);
    }
    return controller;
  };

  render() {
    const { processorOptions, processorRecords, selectedProcessor } = this.state;
    console.log('ProcessorStep render:', {
      processorOptions,
      processorRecords,
      selectedProcessor,
      props: this.props
    });

    return (
      <div className={styles.processorStep}>
        <div className={styles.processorSelect}>
          <ProcessorField
            required
            name="processor"
            options={processorOptions}
            findProcessorById={this.findProcessorById}
            autoFocus
          />

          <FormSpy subscription={{ values: true }}>
            {({ values, form }) => {
              console.log('FormSpy values:', {
                processorRecordIds: values.processorRecordIds,
                processor: values.processor,
                formState: form.getState()
              });

              return processorRecords.length > 0 && (
                <div style={{ marginTop: '20px' }}>
                  <Label required={true}>
                    <Trans noMd>declaration_form.processor_step.processors</Trans>
                  </Label>

                  {processorRecords.map((processor) => {
                    const isChecked = values.processorRecordIds?.includes(processor.id);
                    
                    return (
                      <div key={processor.id} style={{ marginTop: '5px' }}>
                        <Checkbox
                          className={styles.row}
                          id={processor.id}
                          value={processor.id}
                          name={`processor_${processor.id}`}
                          label={processor.name}
                          checked={isChecked}
                          onChange={() => {
                            const currentIds = values.processorRecordIds || [];
                            const newIds = isChecked 
                              ? currentIds.filter(id => id !== processor.id)
                              : [...currentIds, processor.id];
                            
                            console.log('Checkbox onChange:', {
                              processorId: processor.id,
                              currentIds,
                              newIds,
                              isChecked
                            });
                            
                            form.change('processorRecordIds', newIds);
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              );
            }}
          </FormSpy>
        </div>

        <div className={styles.separator}>
          <div className={styles.line} />
          <span className={styles.or}>
            <Trans noMd>shared.or</Trans>
          </span>
          <div className={styles.line} />
        </div>

        <Form onSubmit={this.createNewProcessor}>
          {({ handleSubmit }) => (
            <div>
              <ProcessorFormFields />
              <div className={styles.buttonRow}>
                <Button kind="primary" onClick={handleSubmit} type="submit">
                  <Trans noMd>declaration_form.processor_step.create_button</Trans>
                </Button>
              </div>
            </div>
          )}
        </Form>
      </div>
    );
  }
}

export default withNamespaces()(withToast(ProcessorStep));

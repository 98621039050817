import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormSpy } from 'react-final-form';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import { withNamespaces } from 'react-i18next';

import withToast from 'src/components/Toast/withToast/withToast';

import {EditorState} from "draft-js"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import MultiPageForm from 'src/components/shared/MultiPageForm/MultiPageForm';

import ContractDeclarationDetails from 'src/components/shared/ContractDeclarationDetails/ContractDeclarationDetails';
import InputField from 'src/components/shared/InputField/InputField';
import Checkbox from 'src/components/shared/Checkbox/Checkbox';
import TextEditor from 'src/components/shared/TextEditorField/TextEditorField';
import { declarationFormPropTypes } from 'src/prop_types/declaration_prop_types';

import { getOrganizationDetails } from 'src/api/organization';
import { toCamelCase } from 'src/utils/caseMappers';

import Footer from './Footer/Footer';
import ProcessorStep from './ProcessorStep/ProcessorStep';
import ValidityField from './ValidityField/ValidityField';

import styles from './DeclarationForm.module.scss';

const Page = props => <MultiPageForm.Page className={styles.declarationForm} {...props} />;

class DeclarationForm extends PureComponent {
  state = {
    organization: {},
    editorState: EditorState.createEmpty(),
  };

  componentDidMount() {
    const { declaration: { organization } = {}, openToast, t } = this.props;

    if (!organization) {
      getOrganizationDetails()
        .then(({ attributes }) => {
          this.setState({
            organization: toCamelCase(attributes),
          });
        })
        .catch(() => {
          openToast({
            message: t('declaration_form.details_step.errors.fetch_organization'),
            type: 'danger',
            inModal: true,
          });
        });
    }
  }

  onSubmit = declaration => {
    const { onSubmit } = this.props;
    
    // Get selected processor records
    const selectedProcessorRecords = (declaration.processorRecordIds || [])
      .map(id => declaration.processor?.processorRecords?.find(r => r.id === id))
      .filter(Boolean);

    // Create the final declaration with proper structure
    const finalDeclaration = {
      ...this.appendValidityDate(declaration),
      processor_id: declaration.processor?.id,
      processor_record_ids: declaration.processorRecordIds || [],
      processable_processor_records: selectedProcessorRecords.map(record => ({
        processor_record: {
          id: record.id,
          name: record.name,
          companyName: record.companyName,
          contactName: record.contactName,
          contactEmail: record.contactEmail,
          contactPhone: record.contactPhone
        }
      }))
    };
    
    console.log('Submitting declaration with processor records:', finalDeclaration);
    onSubmit(finalDeclaration);
  };

  appendValidityDate = declaration => {
    const validUntil = moment().add(declaration.validity, declaration.validityUnit);

    return {
      ...declaration,
      validUntil: declaration.validity && declaration.validityUnit ? validUntil : null,
    };
  };

  checkForThirdParties = ({ thirdParties, dataTransfer, ...declaration }) => ({
    ...declaration,
    thirdParties: dataTransfer ? thirdParties : [],
  });

  prepareInitialValues = declaration => {
    const {
      processor,
      processorRecordIds = [],
      validityUnit,
      validity,
    } = declaration;

    const { organization } = this.state;

    return {
      organization,
      ...declaration,
      processorRecordIds,
      processor,
      processorId: processor?.id,
      validityUnit: validityUnit || 'months',
      validity: validity || 12,
    };
  };

  render() {
    const { t, declaration } = this.props;
    const { appendValidityDate, onSubmit } = this;
    const initialValues = this.prepareInitialValues(declaration);

    return (
      <MultiPageForm
        initialValues={initialValues}
        footer={Footer}
        onSubmit={onSubmit}
        mutators={{
          setProcessorField: ([processor], state, { changeValue }) => {
            console.log('Setting processor:', processor);
            changeValue(state, 'processor', () => processor);
            changeValue(state, 'processorId', () => processor?.id);
            changeValue(state, 'processorRecordIds', () => []);
          },
          setProcessorRecords: ([recordIds], state, { changeValue }) => {
            console.log('Setting processor records:', {
              newIds: recordIds,
              currentState: state.formState.values.processorRecordIds
            });
            changeValue(state, 'processorRecordIds', () => [...recordIds]);
          },
          setValue: ([field, value], state, { changeValue }) => {
            changeValue(state, field, () => value)
          }
        }}
      >
        <Page>
          <Fragment>
            <InputField
              className={styles.row}
              label={t('declaration_form.general_info.title')}
              name="title"
              required
              autoFocus
            />

            <Checkbox
              className={styles.row}
              id="test"
              name="test"
              disabled={declaration.contractsCount > 0}
              label={t('declaration_form.general_info.contract.test')}
            />

            <Checkbox
              className={styles.row}
              id="active"
              name="active"
              label="Active"
            />

            <Checkbox
              className={styles.row}
              id="required"
              name="required"
              label="Required"
            />

            <InputField
              className={styles.row}
              label="Version"
              name="version"
            />

            <FormSpy>
              {({ form: { mutators } }) => (
                <TextEditor
                className={styles.row}
                label={t('declaration_form.general_info.purpose')}
                name="purpose"
                required
                setFormValue={mutators.setValue}
                />
              )}
            </FormSpy>
          </Fragment>
        </Page>

        <Page>
          <Fragment>
            <FormSpy>
              {({ form: { mutators } }) => (
                <TextEditor
                className={styles.row}
                label={t('declaration_form.general_info.acceptance_text')}
                name="body"
                required
                setFormValue={mutators.setValue}
                />
              )}
            </FormSpy>
            <FormSpy>
              {({ form: { mutators } }) => (
                <TextEditor
                className={styles.row}
                label={t('declaration_form.general_info.withdrawal')}
                name="withdrawDetails"
                required
                setFormValue={mutators.setValue}
                />
              )}
            </FormSpy>
          </Fragment>
        </Page>
        <Page>
          <FormSpy>
            {({ form: { mutators } }) => (
              <ProcessorStep 
                setProcessorField={mutators.setProcessorField}
                setProcessorRecords={mutators.setProcessorRecords}
                processorId={initialValues.processorId}
                processorRecordIds={initialValues.processorRecordIds}
              />
            )}
          </FormSpy>
        </Page>
        <Page>
          <Fragment>
            <FormSpy>
              {({ values }) => <ValidityField autoFocus unit={values.validityUnit} />}
            </FormSpy>
          </Fragment>
        </Page>
        <Page>
          <FormSpy>
            {({ values }) => {
              console.log('Preview step - Raw form values:', values);

              // Get selected processor records
              const selectedProcessorRecords = (values.processorRecordIds || [])
                .map(id => values.processor?.processorRecords?.find(r => r.id === id))
                .filter(Boolean);

              console.log('Preview step - Selected records:', selectedProcessorRecords);

              // Transform controller data
              const transformedController = values.processor ? {
                id: values.processor.id,
                name: values.processor.name,
                companyName: values.processor.companyName,
                contactName: values.processor.contactName,
                contactEmail: values.processor.contactEmail,
                contactPhone: values.processor.contactPhone
              } : null;

              // Create preview declaration
              const previewDeclaration = {
                ...appendValidityDate(values),
                processor: transformedController,
                processorRecords: selectedProcessorRecords.map(record => ({
                  id: record.id,
                  name: record.name,
                  companyName: record.companyName,
                  contactName: record.contactName,
                  contactEmail: record.contactEmail,
                  contactPhone: record.contactPhone
                })),
                processorRecordNames: selectedProcessorRecords.map(record => record.name),
                processorRecordIds: values.processorRecordIds
              };

              console.log('Preview step - Final declaration:', previewDeclaration);

              return (
                <div className={styles.scrollBox}>
                  <ContractDeclarationDetails
                    declaration={previewDeclaration}
                    isEditable
                    key={previewDeclaration.processorRecordIds?.join(',')}
                  />
                </div>
              );
            }}
          </FormSpy>
        </Page>
      </MultiPageForm>
    );
  }
}

DeclarationForm.defaultProps = {
  declaration: {}, // eslint-disable-line react/default-props-match-prop-types
  onSubmit: null,
};

DeclarationForm.propTypes = {
  ...declarationFormPropTypes,

  onSubmit: PropTypes.func,
};

export default withNamespaces()(withToast(DeclarationForm));

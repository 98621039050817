import React, { PureComponent } from 'react';
import cn from 'classnames';
import { withNamespaces } from 'react-i18next';
import PropTypes from 'prop-types';

import Trans from 'src/components/shared/Trans/Trans';
import Tooltip from 'src/components/shared/Tooltip/Tooltip';
import Button from 'src/components/shared/Button/Button';
import ContractDeclarationDetails from 'src/components/shared/ContractDeclarationDetails/ContractDeclarationDetails';
import { declarationPropTypes } from 'src/prop_types/declaration_prop_types';

import styles from './ContractDeclarationDetailsView.module.scss';

class ContractDeclarationDetailsView extends PureComponent {
  state = {
    folded: this.props.folded,
  };

  toggle = () => this.setState(prevState => ({ folded: !prevState.folded }));

  render() {
    const { folded } = this.state;
    const { declaration, t } = this.props;

    console.log('ContractDeclarationDetailsView - declaration:', declaration);

    return (
      <div className={cn(styles.declarationDetails, { [styles.folded]: folded })}>
        {!declaration.id && (
          <Tooltip text={t('contract_declaration.external_declaration_tooltip')} position="left">
            <div className={styles.pill}>
              <Trans noMd>shared.external_declaration_uppercase</Trans>
            </div>
          </Tooltip>
        )}
        {folded && (
          <div className={styles.overlay}>
            <Button kind="primary" className={styles.unfold} onClick={this.toggle} type="button">
              <Trans noMd>shared.see_more</Trans>
            </Button>
          </div>
        )}

        <ContractDeclarationDetails {...{ declaration }} />
      </div>
    );
  }
}

ContractDeclarationDetailsView.propTypes = {
  ...declarationPropTypes,
  folded: PropTypes.bool,
  t: PropTypes.func.isRequired,
};

ContractDeclarationDetailsView.defaultProps = {
  folded: true,
};

export default withNamespaces()(ContractDeclarationDetailsView);

import React from 'react';
import PropTypes from 'prop-types';
import Trix from "trix";
import "trix/dist/trix";
import "trix/dist/trix.css";
import "./Editor.scss";

class Editor extends React.Component {
  constructor(props) {
    super(props);
    this.editorInput = React.createRef();
    this.editorId = `trix-${props.name}`;
    this.state = {
      isHtmlMode: false,
      htmlContent: props.value || '',
      key: Date.now()
    };
    console.log(`Constructor - Editor ${this.editorId}`);
  }

  shouldComponentUpdate(nextProps, nextState) {
    // Only update if these specific things change
    return (
      this.state.isHtmlMode !== nextState.isHtmlMode ||
      this.state.key !== nextState.key ||
      this.props.value !== nextProps.value
    );
  }

  cleanupAllToolbars = () => {
    // Remove all toolbars for this editor
    if (this.editorInput.current) {
      // First, remove any existing toolbar attached to the editor
      const attachedToolbar = this.editorInput.current.toolbarElement;
      if (attachedToolbar) {
        attachedToolbar.remove();
      }

      // Then remove any orphaned toolbars with this editor's ID
      const orphanedToolbars = document.querySelectorAll(`trix-toolbar[data-editor-id="${this.editorId}"]`);
      orphanedToolbars.forEach(toolbar => toolbar.remove());
    }
  }

  removeFileTools = () => {
    if (this.editorInput.current && this.editorInput.current.toolbarElement) {
      const toolbarElement = this.editorInput.current.toolbarElement;
      // Mark this toolbar with our editor ID
      toolbarElement.setAttribute('data-editor-id', this.editorId);
      
      const fileTools = toolbarElement.querySelector(".trix-button-group--file-tools");
      if (fileTools) {
        fileTools.remove();
      }
    }
  }

  toggleEditMode = () => {
    const { isHtmlMode } = this.state;

    if (!isHtmlMode && this.editorInput.current) {
      // Get current content when switching to HTML mode
      let currentContent = this.editorInput.current.innerHTML;
      
      // Basic cleanup and formatting
      currentContent = currentContent
        .replace(/<!--block-->/g, '')
        .replace(/<div><br><\/div>/g, '')
        // Format lists with preserved whitespace
        .replace(/<ul>/g, '\n<ul>')
        .replace(/<\/ul>/g, '</ul>\n')
        .replace(/<li>/g, '\n  <li>')
        .replace(/<\/li>/g, '</li>\n')  // Add newline after </li>
        // Format headings with preserved whitespace
        .replace(/<h([1-6])>/g, '\n<h$1>')
        .replace(/<\/h([1-6])>/g, '</h$1>\n')
        // Preserve consecutive newlines
        .replace(/\n\n+/g, '\n\n')
        // Clean up any trailing whitespace
        .trim();

      this.setState({ 
        isHtmlMode: true,
        htmlContent: currentContent
      });
    } else {
      // Preserve exact HTML content when switching back
      const preservedContent = this.state.htmlContent
        // Ensure newlines are preserved
        .replace(/\n\s*/g, '\n')
        .trim();

      this.setState({ isHtmlMode: !isHtmlMode }, () => {
        if (!this.state.isHtmlMode) {
          this.cleanupAllToolbars();
          
          this.setState({ key: Date.now() }, () => {
            requestAnimationFrame(() => {
              if (this.editorInput.current) {
                setTimeout(() => {
                  this.removeFileTools();
                  this.addHeadingButtons();
                  this.editorInput.current.editor.loadHTML(preservedContent);
                  this.props.onChange(preservedContent);
                }, 50);
              }
            });
          });
        }
      });
    }
  }

  componentDidMount() {
    console.log(`ComponentDidMount - Editor ${this.editorId}`);
    
    // Change default paragraph behavior
    Trix.config.blockAttributes.default = {
      tagName: 'p',
      breakOnReturn: true
    };

    // Add custom heading attributes
    Trix.config.blockAttributes.heading2 = {
      tagName: 'h2',
      terminal: true,
      breakOnReturn: true,
      group: false
    };

    Trix.config.blockAttributes.heading3 = {
      tagName: 'h3',
      terminal: true,
      breakOnReturn: true,
      group: false
    };

    Trix.config.blockAttributes.heading4 = {
      tagName: 'h4',
      terminal: true,
      breakOnReturn: true,
      group: false
    };

    Trix.config.blockAttributes.heading5 = {
      tagName: 'h5',
      terminal: true,
      breakOnReturn: true,
      group: false
    };

    Trix.config.blockAttributes.heading6 = {
      tagName: 'h6',
      terminal: true,
      breakOnReturn: true,
      group: false
    };

    if (this.editorInput.current) {
      setTimeout(() => {
        this.removeFileTools();
        this.addHeadingButtons();
      }, 50);

      this.editorInput.current.addEventListener("trix-change", (event) => {
        const newContent = event.target.innerHTML;
        this.setState({ htmlContent: newContent });
        this.props.onChange(newContent);
      });
    }
  }

  addHeadingButtons = () => {
    if (this.editorInput.current && this.editorInput.current.toolbarElement) {
      const toolbarElement = this.editorInput.current.toolbarElement;
      const textToolsGroup = toolbarElement.querySelector(".trix-button-group--block-tools");
      
      if (textToolsGroup) {
        // Add H2 button
        const h2Button = document.createElement('button');
        h2Button.type = 'button';
        h2Button.classList.add('trix-button');
        h2Button.setAttribute('data-trix-attribute', 'heading2');
        h2Button.setAttribute('title', 'Heading 2');
        h2Button.innerHTML = 'h2';

        // Add H3 button
        const h3Button = document.createElement('button');
        h3Button.type = 'button';
        h3Button.classList.add('trix-button');
        h3Button.setAttribute('data-trix-attribute', 'heading3');
        h3Button.setAttribute('title', 'Heading 3');
        h3Button.innerHTML = 'h3';

        // Insert buttons after the heading (Tt) button
        const headingButton = textToolsGroup.querySelector('[data-trix-attribute="heading1"]');
        if (headingButton) {
          headingButton.insertAdjacentElement('afterend', h3Button);
          headingButton.insertAdjacentElement('afterend', h2Button);
        } else {
          textToolsGroup.appendChild(h2Button);
          textToolsGroup.appendChild(h3Button);
        }
      }
    }
  }

  handleHtmlChange = (event) => {
    const newContent = event.target.value;
    this.setState({ htmlContent: newContent });
    this.props.onChange(newContent);
  }

  render() {
    const { isHtmlMode, htmlContent } = this.state;

    return (
      <div>
        <input 
          type="hidden" 
          id={this.editorId}
          value={this.props.value} 
          name={this.props.name}
        />
        {isHtmlMode ? (
          <textarea
            value={htmlContent}
            onChange={this.handleHtmlChange}
            className={this.props.className}
            style={{ 
              width: '100%', 
              minHeight: '200px',
              whiteSpace: 'pre-wrap',
              fontFamily: 'monospace',
              fontSize: '14px',
              lineHeight: '1.5',
              padding: '12px'
            }}
          />
        ) : (
          <trix-editor 
            key={this.state.key}
            input={this.editorId}
            ref={this.editorInput}
            class={this.props.className}
            data-editor-id={this.editorId}
          />
        )}
        <a 
          href="#" 
          onClick={(e) => { e.preventDefault(); this.toggleEditMode(); }}
          style={{ display: 'block', marginTop: '10px', fontSize: '14px' }}
        >
          {isHtmlMode ? 'Save changes and return to WYSIWYG' : 'Edit raw HTML'}
        </a>
      </div>
    );
  }

  componentWillUnmount() {
    console.log(`ComponentWillUnmount - Editor ${this.editorId}`);
    this.cleanupAllToolbars();
  }
}

Editor.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  className: PropTypes.string
};

export default Editor;
import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormSpy } from 'react-final-form';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import { withNamespaces } from 'react-i18next';

import withToast from 'src/components/Toast/withToast/withToast';

import {EditorState} from "draft-js"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import MultiPageForm from 'src/components/shared/MultiPageForm/MultiPageForm';

import DeclarationDetails from 'src/components/shared/DeclarationDetails/DeclarationDetails';
import InputField from 'src/components/shared/InputField/InputField';
import Checkbox from 'src/components/shared/Checkbox/Checkbox';
import TextEditor from 'src/components/shared/TextEditorField/TextEditorField';
import { declarationFormPropTypes } from 'src/prop_types/declaration_prop_types';

import { getOrganizationDetails } from 'src/api/organization';
import { toCamelCase } from 'src/utils/caseMappers';

import Footer from './Footer/Footer';
import ProcessorStep from './ProcessorStep/ProcessorStep';
import PersonalDataCategoriesStep from './PersonalDataCategoriesStep/PersonalDataCategoriesStep';
import ConditionalFormPart from './ConditionalFormPart/ConditionalFormPart';
import ThirdPartiesField from './ThirdPartiesField/ThirdPartiesField';
import ValidityField from './ValidityField/ValidityField';

import styles from './DeclarationForm.module.scss';

const Page = props => <MultiPageForm.Page className={styles.declarationForm} {...props} />;

class DeclarationForm extends PureComponent {
  state = {
    organization: {},
    editorState: EditorState.createEmpty(),
  };

  componentDidMount() {
    const { declaration: { organization } = {}, openToast, t } = this.props;

    if (!organization) {
      getOrganizationDetails()
        .then(({ attributes }) => {
          this.setState({
            organization: toCamelCase(attributes),
          });
        })
        .catch(() => {
          openToast({
            message: t('declaration_form.details_step.errors.fetch_organization'),
            type: 'danger',
            inModal: true,
          });
        });
    }
  }

  onSubmit = declaration => {
    const { onSubmit } = this.props;
    onSubmit(this.appendValidityDate(declaration));
  };

  appendValidityDate = declaration => {
    const validUntil = moment().add(declaration.validity, declaration.validityUnit);

    return {
      ...declaration,
      validUntil: declaration.validity && declaration.validityUnit ? validUntil : null,
    };
  };

  checkForThirdParties = ({ thirdParties, dataTransfer, ...declaration }) => ({
    ...declaration,
    thirdParties: dataTransfer ? thirdParties : [],
  });

  prepareInitialValues = declaration => {
    const {
      processor,
      thirdParties = [],
      automatedDecisionMakingDescription,
      personalDataCategories = [],
      processorRecordIds = [],
    } = declaration;

    console.log('Preparing initial values with processorRecordIds:', processorRecordIds);

    const { organization } = this.state;
    const { t } = this.props;

    return {
      thirdParties,
      purposeTitle: t('consent_declaration.purpose_title'),
      personalDataCategoriesTitle: t('consent_declaration.personal_data_categories_title'),
      consentValidTitle: t('consent_declaration.consent_valid_title'),
      processingOfDataTitle: t('consent_declaration.processing_of_data_title'),
      processorTitle: t('consent_declaration.processor_title'),
      companyDetailsTitle: t('consent_declaration.company_details_title'),
      withdrawalTitle: t('consent_declaration.withdrawal_title'),
      automatedDecisionMakingTitle: t('consent_declaration.automated_decision_making_title'),
      thirdPartiesTitle: t('consent_declaration.third_parties_title'),
      validityIndefiniteTitle: t('shared.indefinite'),
      personalDataCategoriesDescription: t(
        'consent_declaration.personal_data_categories_description',
      ),
      processorDescription: t('consent_declaration.processor_description'),
      organization,
      ...declaration,
      dataTransfer: thirdParties.length > 0,
      automatedDecisionMakingEnabled: !isEmpty(automatedDecisionMakingDescription),
      personalDataCategories,
      processorRecordIds,
      processor,
      processorId: processor?.id,
    };
  };

  render() {
    const { t, declaration } = this.props;
    const { appendValidityDate, onSubmit, checkForThirdParties } = this;
    const initialValues = this.prepareInitialValues(declaration);

    return (
      <MultiPageForm
        initialValues={initialValues}
        footer={Footer}
        onSubmit={onSubmit}
        mutators={{
          setProcessorField: ([processor], state, { changeValue }) => {
            console.log('Setting processor:', processor);
            changeValue(state, 'processor', () => processor);
            changeValue(state, 'processorId', () => processor.id);
          },
          setProcessorRecords: ([recordIds], state, { changeValue }) => {
            console.log('Setting processor records:', {
              newIds: recordIds,
              currentState: state.formState.values.processorRecordIds
            });
            
            // Update the form values
            changeValue(state, 'processorRecordIds', () => [...recordIds]);
            
            const processor = state.formState.values.processor;
            if (processor?.processorRecords) {
              const selectedRecords = recordIds
                .map(id => processor.processorRecords.find(pr => pr.id === id))
                .filter(Boolean)
                .map(record => ({
                  processor_record: {
                    id: record.id,
                    name: record.name,
                    companyName: record.companyName,
                    contactName: record.contactName,
                    contactEmail: record.contactEmail,
                    contactPhone: record.contactPhone
                  }
                }));
              
              changeValue(state, 'processable_processor_records', () => selectedRecords);
            }
          },
          // expect (field, value) args from the mutator
          setValue: ([field, value], state, { changeValue }) => {
            changeValue(state, field, () => value)
          },
          appendPDC: ([tag], state, { changeValue }) => {
            const {
              formState: { values: { personalDataCategories: oldPDCs = [] } = {} } = {},
            } = state;

            changeValue(state, 'personalDataCategories', () => [
              ...oldPDCs,
              {
                id: tag.id,
                name: tag.value,
              },
            ]);
          },
          removePDC: ([tag], state, { changeValue }) => {
            const {
              formState: { values: { personalDataCategories: oldPDCs = [] } = {} } = {},
            } = state;

            changeValue(state, 'personalDataCategories', () =>
              oldPDCs.filter(({ id }) => id !== tag.id),
            );
          },
        }}
      >
        <Page>
          <Fragment>
            <InputField
              className={styles.row}
              label={t('declaration_form.general_info.title')}
              name="title"
              required
              autoFocus
            />

            <Checkbox
              className={styles.row}
              id="test"
              name="test"
              disabled={declaration.consentsCount > 0}
              label={t('declaration_form.general_info.consent.test')}
            />

            <Checkbox
              className={styles.row}
              id="active"
              name="active"
              label="Active"
            />

            <Checkbox
              className={styles.row}
              id="required"
              name="required"
              label="Required"
            />

            <InputField
              className={styles.row}
              label="Version"
              name="version"
            />

            <InputField
              className={styles.row}
              hideOptional={ true }
              label={ t('declaration_form.general_info.request_consent_text') }
              placeholder={ t('declaration_form.general_info.request_consent_text_placeholder') }
              name="requestConsentText"
            />

            <FormSpy>
              {({ form: { mutators } }) => (
                <TextEditor
                className={styles.row}
                label={t('declaration_form.general_info.purpose')}
                name="purpose"
                required
                setFormValue={mutators.setValue}
                />
              )}
            </FormSpy>

            <FormSpy>
              {({ form: { mutators } }) => (
                <TextEditor
                className={styles.row}
                label={t('declaration_form.general_info.acceptance_text')}
                name="body"
                required
                setFormValue={mutators.setValue}
                />
              )}
            </FormSpy>
          </Fragment>
        </Page>
        <Page>
          <Fragment>
            <FormSpy>
              {({ form: { mutators } }) => (
                <TextEditor
                className={styles.row}
                label={t('declaration_form.general_info.processing')}
                name="processing"
                required
                autoFocus
                setFormValue={mutators.setValue}
                />
              )}
            </FormSpy>

            <FormSpy>
              {({ form: { mutators } }) => (
                <TextEditor
                  className={styles.row}
                  label={t('declaration_form.general_info.withdrawal')}
                  name="withdrawDetails"
                  required
                  setFormValue={mutators.setValue}
                />
              )}
            </FormSpy>
          </Fragment>
        </Page>
        <Page>
          <FormSpy>
            {({ form: { mutators } }) => (
              <ProcessorStep 
                setProcessorField={mutators.setProcessorField}
                setProcessorRecords={mutators.setProcessorRecords}
                processorId={initialValues.processorId}
                processorRecordIds={initialValues.processorRecordIds}
              />
            )}
          </FormSpy>
        </Page>
        <Page>
          <FormSpy>
            {({ values, form: { mutators } }) => (
              <PersonalDataCategoriesStep
                selectedCategories={values.personalDataCategories}
                appendPDC={mutators.appendPDC}
                removePDC={mutators.removePDC}
              />
            )}
          </FormSpy>
        </Page>
        <Page>
          <Fragment>
            <FormSpy>
              {({ values }) => <ValidityField autoFocus unit={values.validityUnit} />}
            </FormSpy>
            <ConditionalFormPart
              className={styles.row}
              label={t('declaration_form.time_step.automated_decision_making')}
              name="automatedDecisionMakingEnabled"
              description={t('declaration_form.time_step.automated_decision_making_description')}
            >
              <FormSpy>
                {({ form: { mutators } }) => (
                  <TextEditor
                    label={t('declaration_form.time_step.specific_information')}
                    name="automatedDecisionMakingDescription"
                    required
                    setFormValue={mutators.setValue}
                  />
                )}
              </FormSpy>
            </ConditionalFormPart>
          </Fragment>
        </Page>
        <Page>
          <ConditionalFormPart
            label={t('declaration_form.data_transfer_step.data_transfer')}
            description={t('declaration_form.data_transfer_step.data_transfer_description')}
            name="dataTransfer"
            autoFocus
          >
            <ThirdPartiesField name="thirdParties" />
          </ConditionalFormPart>
        </Page>
        <Page>
          <FormSpy>
            {({ values }) => {
              console.log('Preview step - Raw form values:', values);

              // Get selected processor records
              const selectedProcessorRecords = (values.processorRecordIds || [])
                .map(id => values.processor?.processorRecords?.find(r => r.id === id))
                .filter(Boolean);

              console.log('Preview step - Selected records:', selectedProcessorRecords);

              // Transform controller data
              const transformedController = values.processor ? {
                id: values.processor.id,
                name: values.processor.name,
                companyName: values.processor.companyName,
                contactName: values.processor.contactName,
                contactEmail: values.processor.contactEmail,
                contactPhone: values.processor.contactPhone
              } : null;

              // Create preview declaration
              const previewDeclaration = {
                ...checkForThirdParties(appendValidityDate(values)),
                processor: transformedController,
                // Use processorRecords instead of processable_processor_records
                processorRecords: selectedProcessorRecords.map(record => ({
                  id: record.id,
                  name: record.name,
                  companyName: record.companyName,
                  contactName: record.contactName,
                  contactEmail: record.contactEmail,
                  contactPhone: record.contactPhone
                })),
                processorRecordNames: selectedProcessorRecords.map(record => record.name),
                processorRecordIds: values.processorRecordIds
              };

              console.log('Preview step - Final declaration:', {
                selectedRecords: selectedProcessorRecords,
                processorRecords: previewDeclaration.processorRecords,
                recordNames: previewDeclaration.processorRecordNames
              });

              return (
                <div className={styles.scrollBox}>
                  <DeclarationDetails
                    declaration={previewDeclaration}
                    isEditable
                    key={previewDeclaration.processorRecordIds?.join(',')}
                  />
                </div>
              );
            }}
          </FormSpy>
        </Page>
      </MultiPageForm>
    );
  }
}

DeclarationForm.defaultProps = {
  declaration: {}, // eslint-disable-line react/default-props-match-prop-types
  onSubmit: null,
};

DeclarationForm.propTypes = {
  ...declarationFormPropTypes,

  onSubmit: PropTypes.func,
};

export default withNamespaces()(withToast(DeclarationForm));

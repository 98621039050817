import React from 'react';
import PropTypes from 'prop-types';

import EmailIcon from 'src/components/image/EmailIcon';
import PhoneIcon from 'src/components/image/PhoneIcon';
import { processorFormPropTypes } from 'src/prop_types/processor_prop_types';

import styles from './ProcessorCard.module.scss';

const ProcessorCard = ({
  processor: { name, companyName, contactName, contactEmail, contactPhone },
  number,
}) => (
  <div className={styles.processorCard}>
    <span className={styles.number}>{number}</span>

    <span className={styles.processorName}>{name}</span>
    <span className={styles.companyName}>{companyName}</span>
    <span className={styles.contactPerson}>{contactName}</span>
    <span className={styles.email}>
      <EmailIcon />
      {contactEmail}
    </span>
    <span className={styles.phone}>
      <PhoneIcon />
      {contactPhone}
    </span>
  </div>
);

ProcessorCard.defaultProps = {
  processor: {}, // eslint-disable-line react/default-props-match-prop-types
};

ProcessorCard.propTypes = {
  processor: PropTypes.shape({
    name: PropTypes.string.isRequired,
    companyName: PropTypes.string.isRequired,
    contactName: PropTypes.string.isRequired,
    contactEmail: PropTypes.string.isRequired,
    contactPhone: PropTypes.string.isRequired,
  }).isRequired,
  number: PropTypes.number.isRequired,
};

export default ProcessorCard;
